import React, { useEffect, useState } from "react";
import style from "../../Assents/Style/ProfessionalCertification.module.css";
import image1 from "../../Assents/Images/ProfessionalCertification/pana.svg";
import icon1 from "../../Assents/Images/ProfessionalCertification/Group 51 (1).svg";
import icon2 from "../../Assents/Images/ProfessionalCertification/Vector 9.png";
import {
  MenuItem,
  Tab,
  Pagination,
  Select,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import chemistryIcon from '../../Assents/Images/ADMIN/Specialties/Beaker.png';

import { useDispatch } from "react-redux";
import { HandelGetAllMainSpecialty } from "../../store/SpecialtiesSlice";
import ProfessionalSpecialties from "../../Components/ProfessionalCertification/ProfessionalSpecialties";
import { HandelPutCountOfVisitors } from "../../store/AuthSlice";

const ProfessionalCertificationSpecialties = () => {
  //^ prepar to show the data from DB
  // ^ All Data using After Filter
  const [MainDataCount, setMainDataCount] = useState([]);
  const itemsPerPage = 6;

  // ^ Get All Data

  useEffect(() => {
    GetAllMainSpecialties();
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [filteredData, setFilteredData] = useState({
    ScientificData: [],
    HumanAndLiteraryData: [],
    AdministrativeData: [],
    HealthyData: []
  });

  const refreshSpecialties = () => {
    GetAllMainSpecialties()
  };
  const [data, setData] = useState({
    ScientificData: [],
    HumanAndLiteraryData: [],
    AdministrativeData: [],
    HealthyData: []
  });
  const [pagination, setPagination] = useState({
    currentPageForScientificData: 1,
    currentPageForHumanAndLiteraryData: 1,
    currentPageForAdministrativeData: 1,
    currentPageForHealthyData: 1
  });

  const handleSelectChange = (event) => {
    setValue(event.target.value);
  };
  const handlePageChange = (type, value) => {
    setPagination(prevState => ({
      ...prevState,
      [`currentPageFor${type}`]: value
    }));
  };

  const getCurrentData = (type) => {
    const filtered = filteredData[type];
    const currentPage = pagination[`currentPageFor${type}`];
    return filtered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  };

  const GetAllMainSpecialties = async () => {
    const res = await dispatch(HandelGetAllMainSpecialty());
    const MainData = res.payload.data;
    setMainDataCount(MainData.length);
    const ScientificData = MainData.filter((item) => item.Type === "علمي");
    const HumanAndLiteraryData = MainData.filter((item) => item.Type === "إنساني وأدبي");
    const AdministrativeData = MainData.filter((item) => item.Type === "إداري");
    const HealthyData = MainData.filter((item) => item.Type === "صحي");

    setData({
      ScientificData,
      HumanAndLiteraryData,
      AdministrativeData,
      HealthyData
    });

    setFilteredData({
      ScientificData,
      HumanAndLiteraryData,
      AdministrativeData,
      HealthyData
    });
  };

  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    GetAllMainSpecialties();
    window.scrollTo(0, 0);
  }, []);

  const renderTabContent = (type, typeName) => {
    const currentData = getCurrentData(typeName);
    return (
      <>
        {currentData.length > 0 ? (
          <>
            <div className='row m-0 gy-3 px-lg-5'>
              {currentData.map((el) => (
                <ProfessionalSpecialties Type={el.Type} id={el._id} key={el.id} Img={el.Image.secure_url} TypeIcon={chemistryIcon} Title={el.Title} Desc={el.Description}  onUpdate={refreshSpecialties}/>
              ))}
            </div>
            <div className='d-flex justify-content-center my-5'>
              <Pagination
                count={Math.ceil(filteredData[typeName].length / itemsPerPage)}
                page={pagination[`currentPageFor${typeName}`]}
                onChange={(event, value) => handlePageChange(typeName, value)}
                variant="outlined"
                className='text-danger'
                shape="rounded"
              />
            </div>
          </>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <Pagination count={0} variant="outlined" className='text-danger' shape="rounded" />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={style.font}>
      <div className="mt-5 text-center">
        <p style={{ fontSize: "35px", fontWeight: 500 }}>الشهادات الاحترافية</p>
      </div>
      {/* 1 */}
      <div className="container mt-5">
        <div className="row m-0 gy-3 d-flex align-items-center">
          <div className="col-md-6">
            <p style={{ fontSize: "22px", color: "rgba(70, 70, 70, 1)" }}>
              الشهادات الاحترافية هي مؤهلات تُمنح للأفراد الذين يستوفون معايير
              معينة في مجالات تخصصهم. تُعتبر هذه الشهادات إثباتًا للمهارات
              والمعرفة التي يمتلكها الفرد في مجال معين، وغالبًا ما تكون معترف
              بها من قبل الجهات المهنية والصناعية المختلفة. يُعتمد على هذه
              الشهادات لتأكيد الكفاءة المهنية والقدرة على الأداء بكفاءة
              واحترافية في بيئة العمل.
            </p>
          </div>
          <div className="col-md-6 d-none d-lg-block">
            <img src={image1} alt="" className="w-100" />
          </div>
        </div>
      </div>
      {/* 2 */}
      <div className={[style.bgImage, "py-5 mt-5 text-center"].join(" ")}>
        <div className="pt-5 ps-5 justify-content-center d-flex">
          <div>
            <img src={icon1} alt="" />
          </div>
          <div className="pt-5 ps-4">
            <p style={{ fontSize: "60px", fontWeight: "bold" }}>
              {MainDataCount}
            </p>
          </div>
        </div>
        <div style={{ fontSize: "30px" }}>
          <p>تخصص رئيسي</p>
        </div>
      </div>
      {/* 3 */}
      <div className="mt-5">
        {/* header */}
        <div className="text-center">
          <p className="m-0" style={{ fontSize: "30px", fontWeight: 400 }}>
            المسارات
          </p>
          <div className="pt-0 mt-0">
            <img src={icon2} alt="" className="pe-5 me-5 pt-0" />
          </div>
        </div>
        {/* show the Data from DB */}
        <div>
          <div className='d-flex justify-content-center my-5'>
        {isSmallScreen ? (
          <Select
            value={value}
            onChange={handleSelectChange}
            aria-label="wrapped label select example"
          >
            <MenuItem value="one" className={[style.font, ""].join(' ')} style={{ fontSize: '22px' }}>علمي</MenuItem>
            <MenuItem value="two" className={[style.font, ""].join(' ')} style={{ fontSize: '22px' }}>إنساني وأدبي</MenuItem>
            <MenuItem value="three" className={[style.font, ""].join(' ')} style={{ fontSize: '22px' }}>إداري</MenuItem>
            <MenuItem value="four" className={[style.font, ""].join(' ')} style={{ fontSize: '22px' }}>صحي</MenuItem>
          </Select>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
            className='d-flex justify-content-center'
          >
            <Tab
              value="one"
              color='rgba(101, 101, 101, 1)'
              label="علمي"
              wrapped
              className='border-bottom border-2 me-4'
              style={{ fontSize: '22px' }}
            />
            <Tab
              value="two"
              label="إنساني وأدبي"
              className='border-bottom border-2 me-4'
              style={{ fontSize: '22px' }}
            />
            <Tab
              value="three"
              label="إداري"
              className='border-bottom border-2 me-4'
              style={{ fontSize: '22px' }}
            />
            <Tab
              value="four"
              label="صحي"
              className='border-bottom border-2 me-4'
              style={{ fontSize: '22px' }}
            />
          </Tabs>
        )}
      </div>

      {value === "one" && renderTabContent("ScientificData", "ScientificData")}
      {value === "two" && renderTabContent("HumanAndLiteraryData", "HumanAndLiteraryData")}
      {value === "three" && renderTabContent("AdministrativeData", "AdministrativeData")}
      {value === "four" && renderTabContent("HealthyData", "HealthyData")}
        </div>

        {/* Desc the path*/}
        <div className="container-fluid my-5">
          {/* header */}
          <div className="row m-0 gy-4">
            <div className="col-md-3">
              <div
                className="rounded-4  py-4 text-center"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontWeight: "600",
                    fontSize: "28px",
                    color: "rgba(31, 42, 68, 1)",
                  }}
                >
                  المسار
                </p>
              </div>
            </div>
            <div className="col-md-9">
              <div
                className="rounded-4  py-4 text-center"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontWeight: "600",
                    fontSize: "28px",
                    color: "rgba(31, 42, 68, 1)",
                  }}
                >
                  شرح المسار
                </p>
              </div>
            </div>
          </div>
          {/* first row */}
          <div className="row m-0 gy-4 ">
            <div className="col-md-3  d-flex align-items-stretch ">
              <div
                className="rounded-4 w-100  py-4 text-center  d-flex align-items-center justify-content-center"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(7, 101, 133, 1)",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontWeight: "600",
                    fontSize: "28px",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  علمي
                </p>
              </div>
            </div>
            <div className="col-md-9 ">
              <div
                className="rounded-4  py-3"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(247, 247, 247, 1)",
                }}
              >
                <p
                  className="m-0 px-lg-5 px-3"
                  style={{
                    fontWeight: "400",
                    fontSize: "17px",
                    color: "rgba(101, 101, 101, 1)",
                  }}
                >
                  يركز على دراسة العلوم الطبيعية والتطبيقية مثل الرياضيات،
                  الفيزياء، الكيمياء، والأحياء. يشمل تطوير الفهم النظري
                  والتطبيقي لهذه العلوم، وإجراء التجارب والأبحاث العلمية. يهدف
                  هذا المسار إلى إعداد الطلاب لمهن في المجالات العلمية والهندسية
                  والطبية، وتعزيز التفكير النقدي والتحليلي، بالإضافة إلى مهارات
                  حل المشكلات ومهارات التواصل.{" "}
                </p>
              </div>
            </div>
          </div>
          {/* second row */}
          <div className="row m-0 gy-4 d-flex align-items-stretch">
          <div className="col-md-3  d-flex align-items-stretch ">
              <div
                className="rounded-4 w-100  py-4 text-center  d-flex align-items-center justify-content-center"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(92, 215, 251, 1)",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontWeight: "600",
                    fontSize: "28px",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  إنساني وأدبي
                </p>
              </div>
            </div>
            <div className="col-md-9 ">
              <div
                className="rounded-4  py-3"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(247, 247, 247, 1)",
                }}
              >
                <p
                  className="m-0 px-lg-5 px-3"
                  style={{
                    fontWeight: "400",
                    fontSize: "17px",
                    color: "rgba(101, 101, 101, 1)",
                  }}
                >
                  {" "}
                  يتضمن التخصصات التي تناقش الظواهر البشرية والقضايا التي تتطلب
                  التجربة الإنسانية، والخبرات التي يمر بها الإنسان، وتعد من أهم
                  التخصصات التي تكسب معرفة ثقافية وعلمية ونظرية. يهدف هذا المسار
                  إلى تعزيز الفهم الثقافي والإنساني، وإعداد الطلاب لمهن في
                  التعليم، والكتابة، والإعلام، والعمل الثقافي.
                </p>
              </div>
            </div>
          </div>
          {/* third row */}
          <div className="row m-0 gy-4 d-flex align-items-stretch">
          <div className="col-md-3  d-flex align-items-stretch ">
              <div
                className="rounded-4 w-100  py-4 text-center  d-flex align-items-center justify-content-center"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(76, 231, 160, 1)",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontWeight: "600",
                    fontSize: "28px",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  {" "}
                  إداري
                </p>
              </div>
            </div>
            <div className="col-md-9 ">
              <div
                className="rounded-4  py-3"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(247, 247, 247, 1)",
                }}
              >
                <p
                  className="m-0 px-lg-5 px-3"
                  style={{
                    fontWeight: "400",
                    fontSize: "17px",
                    color: "rgba(101, 101, 101, 1)",
                  }}
                >
                  {" "}
                  يعتمد بشكل عام على إعداد شخص مسؤول قادر على اتخاذ القرارات
                  المصيرية الذكية، وذلك عن طريق تعلم الخبرات المختلفة والمرتبطة
                  بإدارة الأعمال والمعرفة بالسوق الدولية والأنظمة الاقتصادية،
                  يشمل ذلك تطوير مهارات القيادة، والتخطيط، وفهم الاستراتيجيات
                  التنظيمية. يهدف هذا المسار إلى إعداد الطلاب لمهن في الإدارة
                  والأعمال، وتعزيز القدرة على إدارة الموارد بكفاءة وتحقيق أهداف
                  المؤسسات. مع تحفيزهم على الخلق، والإبداع، والابتكار، والريادة.
                </p>
              </div>
            </div>
          </div>
          {/* fourth row */}
          <div className="row m-0 gy-4 d-flex align-items-stretch">
          <div className="col-md-3  d-flex align-items-stretch ">
              <div
                className="rounded-4 w-100  py-4 text-center  d-flex align-items-center justify-content-center"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(31, 42, 68, 1)",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontWeight: "600",
                    fontSize: "28px",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  {" "}
                  صحي
                </p>
              </div>
            </div>
            <div className="col-md-9 ">
              <div
                className="rounded-4  py-3"
                style={{
                  boxShadow: "0px 0px 13px rgb(236, 236, 236)",
                  backgroundColor: "rgba(247, 247, 247, 1)",
                }}
              >
                <p
                  className="m-0 px-lg-5 px-3"
                  style={{
                    fontWeight: "400",
                    fontSize: "17px",
                    color: "rgba(101, 101, 101, 1)",
                  }}
                >
                  {" "}
                  يركز على دراسة العلوم الطبية والصحية مثل الطب، التمريض،
                  الصيدلة، وعلوم المختبرات. يشمل ذلك تعلم تشخيص الأمراض وعلاجها،
                  وفهم الرعاية الصحية الوقائية، وتقديم الدعم الطبي للمرضى. يهدف
                  هذا المسار إلى إعداد الطلاب لمهن في القطاع الصحي، وتعزيز الصحة
                  العامة، وتحسين جودة الرعاية الصحية المقدمة للمجتمعات. يركز على
                  دراسة العلوم الطبية والصحية مثل الطب، التمريض، الصيدلة، وعلوم
                  المختبرات. يشمل ذلك تعلم تشخيص الأمراض وعلاجها، وفهم الرعاية
                  الصحية الوقائية، وتقديم الدعم الطبي للمرضى. يهدف هذا المسار
                  إلى إعداد الطلاب لمهن في القطاع الصحي، وتعزيز الصحة العامة،
                  وتحسين جودة الرعاية الصحية المقدمة للمجتمعات.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalCertificationSpecialties;
