import React, { useEffect, useState } from 'react';
import style from '../../../Assents/Style/search.module.css';
import searchicon from '../../../Assents/Images/search/Search_alt_light.svg';
import { MenuItem, Pagination, Select, Tab, Tabs, useMediaQuery } from '@mui/material';
import chemistryIcon from '../../../Assents/Images/ADMIN/Specialties/Beaker.png';
import SpecialtiesComponent from '../../../Components/ADMIN/Specialties/SpecialtiesComponent';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HandelGetAllMainSpecialty } from '../../../store/SpecialtiesSlice';

const Specialties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState({
    ScientificData: [],
    HumanAndLiteraryData: [],
    AdministrativeData: [],
    HealthyData: []
  });

  const refreshSpecialties = () => {
    GetAllMainSpecialties()
  };
  const [data, setData] = useState({
    ScientificData: [],
    HumanAndLiteraryData: [],
    AdministrativeData: [],
    HealthyData: []
  });
  const [pagination, setPagination] = useState({
    currentPageForScientificData: 1,
    currentPageForHumanAndLiteraryData: 1,
    currentPageForAdministrativeData: 1,
    currentPageForHealthyData: 1
  });
  const itemsPerPage = 6;

  const handleSelectChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    handleSearch(value);
  };

  const handleSearch = (searchTerm) => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filterData = (data) => data.filter(item => item.Title.toLowerCase().includes(lowercasedFilter));

    setFilteredData({
      ScientificData: filterData(data.ScientificData),
      HumanAndLiteraryData: filterData(data.HumanAndLiteraryData),
      AdministrativeData: filterData(data.AdministrativeData),
      HealthyData: filterData(data.HealthyData)
    });
  };

  const handlePageChange = (type, value) => {
    setPagination(prevState => ({
      ...prevState,
      [`currentPageFor${type}`]: value
    }));
  };

  const getCurrentData = (type) => {
    const filtered = filteredData[type];
    const currentPage = pagination[`currentPageFor${type}`];
    return filtered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  };

  const GetAllMainSpecialties = async () => {
    const res = await dispatch(HandelGetAllMainSpecialty());
    const MainData = res.payload.data;

    const ScientificData = MainData.filter((item) => item.Type === "علمي");
    const HumanAndLiteraryData = MainData.filter((item) => item.Type === "إنساني وأدبي");
    const AdministrativeData = MainData.filter((item) => item.Type === "إداري");
    const HealthyData = MainData.filter((item) => item.Type === "صحي");

    setData({
      ScientificData,
      HumanAndLiteraryData,
      AdministrativeData,
      HealthyData
    });

    setFilteredData({
      ScientificData,
      HumanAndLiteraryData,
      AdministrativeData,
      HealthyData
    });
  };

  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    GetAllMainSpecialties();
    window.scrollTo(0, 0);
  }, []);

  const renderTabContent = (type, typeName) => {
    const currentData = getCurrentData(typeName);
    return (
      <>
        {currentData.length > 0 ? (
          <>
            <div className='row m-0 gy-3 px-lg-5'>
              {currentData.map((el) => (
                <SpecialtiesComponent Type={el.Type} id={el._id} key={el.id} Img={el.Image.secure_url} TypeIcon={chemistryIcon} Title={el.Title} Desc={el.Description}  onUpdate={refreshSpecialties}/>
              ))}
            </div>
            <div className='d-flex justify-content-center my-5'>
              <Pagination
                count={Math.ceil(filteredData[typeName].length / itemsPerPage)}
                page={pagination[`currentPageFor${typeName}`]}
                onChange={(event, value) => handlePageChange(typeName, value)}
                variant="outlined"
                className='text-danger'
                shape="rounded"
              />
            </div>
          </>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <Pagination count={0} variant="outlined" className='text-danger' shape="rounded" />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={style.font}>
      <div className='container m-auto gy-3 row mt-4 d-flex align-items-center'>
        <div className='col-md-2'>
          <p style={{ fontSize: '22px' }}>التخصصات</p>
        </div>

        <div className='col-md-8'>
          <div style={{ backgroundColor: 'rgba(247, 247, 247, 1)' }} className="input-group rounded-4 py-2">
            <div className="input-group-prepend bg-transparent border-0">
              <span className="input-group-text bg-transparent border-0" id="basic-addon1">
                <img src={searchicon} />
              </span>
            </div>
            <input type="text" className={[style.input, "form-control border-0"].join(" ")} placeholder="ابحث عن ما تريده هنا" onKeyUp={handleInputChange} />
          </div>
        </div>

        <div className='col-md-2'>
          <button onClick={handleInputChange} className='btn text-white w-100 rounded-4 mt-1 py-3' style={{ backgroundColor: 'rgba(31, 42, 68, 1)' }}>بحث</button>
        </div>
      </div>

      <div className='d-flex justify-content-center my-5'>
        {isSmallScreen ? (
          <Select
            value={value}
            onChange={handleSelectChange}
            aria-label="wrapped label select example"
          >
            <MenuItem value="one" className={[style.font, ""].join(' ')} style={{ fontSize: '22px' }}>علمي</MenuItem>
            <MenuItem value="two" className={[style.font, ""].join(' ')} style={{ fontSize: '22px' }}>إنساني وأدبي</MenuItem>
            <MenuItem value="three" className={[style.font, ""].join(' ')} style={{ fontSize: '22px' }}>إداري</MenuItem>
            <MenuItem value="four" className={[style.font, ""].join(' ')} style={{ fontSize: '22px' }}>صحي</MenuItem>
          </Select>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
            className='d-flex justify-content-center'
          >
            <Tab
              value="one"
              color='rgba(101, 101, 101, 1)'
              label="علمي"
              wrapped
              className='border-bottom border-2 me-4'
              style={{ fontSize: '22px' }}
            />
            <Tab
              value="two"
              label="إنساني وأدبي"
              className='border-bottom border-2 me-4'
              style={{ fontSize: '22px' }}
            />
            <Tab
              value="three"
              label="إداري"
              className='border-bottom border-2 me-4'
              style={{ fontSize: '22px' }}
            />
            <Tab
              value="four"
              label="صحي"
              className='border-bottom border-2 me-4'
              style={{ fontSize: '22px' }}
            />
          </Tabs>
        )}
      </div>

      {value === "one" && renderTabContent("ScientificData", "ScientificData")}
      {value === "two" && renderTabContent("HumanAndLiteraryData", "HumanAndLiteraryData")}
      {value === "three" && renderTabContent("AdministrativeData", "AdministrativeData")}
      {value === "four" && renderTabContent("HealthyData", "HealthyData")}

      <div className='text-start ms-5'>
        <button onClick={() => navigate('/Admin/addNewSpecialties')} className='btn text-white ms-auto rounded-4 mt-1 px-5 py-3 mb-5' style={{ backgroundColor: 'rgba(31, 42, 68, 1)' }}>+ اضافة تخصص رئيسي</button>
      </div>
    </div>
  );
};

export default Specialties;
