import React from 'react'
import img from './error.svg'
const NotFound = () => {
  return (
    <div>
      <div className='pt-5 d-flex justify-content-center'>
      <img src={img} className='w-75' alt="" />
    </div>
    </div>
  )
}

export default NotFound
