import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../Assents/Images/navbar/image 1.png";
import style from "../../Assents/Style/Auth.module.css";

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const closeNav = () => setIsNavCollapsed(true);

  return (
    <div>
      <nav className={[style.font, "navbar navbar-expand-lg bg-transparent"].join(" ")}>
        <div className="container-fluid px-lg-5">
          <NavLink className="navbar-brand" to="#">
            <img src={logo} alt="logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`${isNavCollapsed ? 'collapse' : 'collapse show'} navbar-collapse`} id="navbarNav">
            <ul className="navbar-nav w-100 justify-content-between">
              <li className="nav-item">
                <NavLink
                  activeClassName={style.activeNavLink}
                  className="nav-link"
                  to="/"
                  onClick={closeNav}
                >
                  الرئيسية
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName={style.activeNavLink}
                  className="nav-link"
                  to="/AboutUs"
                  onClick={closeNav}
                >
                  من نحن
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName={style.activeNavLink}
                  className="nav-link"
                  to="/OurServices"
                  onClick={closeNav}
                >
                  خدماتنا
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName={style.activeNavLink}
                  className="nav-link"
                  to="/ContactUs"
                  onClick={closeNav}
                >
                  تواصل معنا
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName={style.activeNavLink}
                  className="nav-link"
                  to="/Search"
                  onClick={closeNav}
                >
                  بحث
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName={style.activeNavLink}
                  className={[style.navLink, "nav-link"].join(" ")}
                  to="/Admin"
                  onClick={closeNav}
                >
                  <span>انشاء حساب / تسجيل دخول</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
